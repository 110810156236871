import $ from 'jquery';
//import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
//import 'bootstrap/js/dist/collapse';
//import 'bootstrap/js/dist/*';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	console.log('document ready');
	console.log('test');

	// Projecten slider homepage
	function owlCarouselProjecten(){

		var owl = jQuery('.carousel-container');
		owl.owlCarousel({
		    center: true,
		    loop: true,
		    stagePadding: 0,
		    //useMouseWheel: true,
		    items: 
		    3,
		    margin:10,
		    nav:false,
		    dots: false,
		    responsive:{
		        0:{
		            items:2
		        },
		        600:{
		            items:2
		        },
		        1000:{
		            items:3
		        }
		    }
		});

		owl.on('mousewheel', '.owl-stage', function (e) {
		    if (e.deltaY<0) {
		        owl.trigger('prev.owl');
		    } else {
		        owl.trigger('next.owl');
		    }
		    e.preventDefault();
		});

	}

	owlCarouselProjecten();	
});